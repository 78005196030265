import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Tabs, Carousel, Avatar } from "antd"

import Layout from "../layouts"
import { MEDIA_INTRO } from "../utils/constant"
import { getJobList } from "../api/job"

import "./index.css"
const { TabPane } = Tabs;
export default () => {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    const jobList0 = await getJobList(1, 10, 0);
    const jobList1 = await getJobList(1, 10, 1);
    const jobList2 = await getJobList(1, 10, 2);
    const jobList3 = await getJobList(1, 10, 3);
    const jobList4 = await getJobList(1, 10, 4);
    const jobList5 = await getJobList(1, 10, 5);
    const jobList6 = await getJobList(1, 10, 6);
    setJobs([{
      name: "IT 互联网",
      list: jobList0.data.data.list
    }, {
      name: "金融",
      list: jobList1.data.data.list
    }, {
      name: "房地产 建筑",
      list: jobList2.data.data.list
    }, {
      name: "教育培训",
      list: jobList3.data.data.list
    }, {
      name: "娱乐传媒",
      list: jobList4.data.data.list
    }, {
      name: "法律咨询",
      list: jobList5.data.data.list
    }, {
      name: "采购贸易",
      list: jobList6.data.data.list
    }])
  }

  return (
    <Layout isSpacerDisplay={false} title="Hello 部落">
      <div className="mdnice-homepage-container">
        <div className="mdnice-homepage-carousel-container">
          <h2 className="mdnice-homepage-carousel-title">热门职位</h2>
          <p className="mdnice-homepage-carousel-explain">
            互联网、金融、房地产相关岗位可供选择
          </p>
          <div className="mdnice-homepage-carousel">
            <Carousel
              autoplay
              dots={{ className: "mdnice-homepage-carousel-dots" }}
            >
              <div>
                <img src="https://gitee.com/guanpengchn/picture/raw/master/2021-3-10/1615382617232-33333%E7%9A%84%E5%89%AF%E6%9C%AC.jpg" />
              </div>
              <div>
                <img src="https://gitee.com/guanpengchn/picture/raw/master/2021-3-10/1615382154320-image.png" />
              </div>
              <div>
                <img src="https://gitee.com/guanpengchn/picture/raw/master/2021-3-10/1615382619367-33333.jpg" />
              </div>
            </Carousel>
          </div>
        </div>

        <div className="mdnice-homepage-media-container">
          <h2>更多职位</h2>
          <p className="mdnice-homepage-media-explain">
            发布 100+ 岗位，为更多求职者带来工作机会
          </p>
          <Tabs defaultActiveKey="1" centered>
            {jobs.map((job, index) => (<TabPane tab={job.name} key={index + 1}>
              <div className="mdnice-homepage-media-job">
                {job.list.map(item => (
                  <div key={item.name} className="mdnice-homepage-media-item">
                    <div className="mdnice-homepage-media-content">
                      <h3 className="mdnice-homepage-media-wechat-name">
                        {item.name}
                      </h3>
                      <p className="mdnice-homepage-media-wechat-description">
                        {item.desc && item.desc.slice(0, 30)}
                      </p>
                      <a
                        className="mdnice-homepage-media-wechat-link"
                        href={`/jobs/id/${item.id}`}
                      >
                        查看详情
                      </a>
                    </div>
                    <div className="mdnice-homepage-media-avatar">
                      <Avatar
                        size={{ xs: 64, sm: 64, md: 80, lg: 80, xl: 80 }}
                        style={{ background: "orange" }}
                      >
                        {item.publisherName && item.publisherName.slice(0, 1).toUpperCase()}
                      </Avatar>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
            ))}
          </Tabs>

        </div>
      </div>
    </Layout>
  )
}
